import { IIcon } from './iconConstants'
const ShareIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 22 22'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h22v22H0z' />
        <path
          d='m12.027 15.604-3.85-2.099a3.667 3.667 0 1 1 0-5.01l3.85-2.098a3.667 3.667 0 1 1 .88 1.608l-3.85 2.1a3.674 3.674 0 0 1 0 1.79l3.849 2.1a3.667 3.667 0 1 1-.88 1.609zM5.5 12.834a1.833 1.833 0 1 0 0-3.667 1.833 1.833 0 0 0 0 3.666zm10.083-5.5a1.833 1.833 0 1 0 0-3.667 1.833 1.833 0 0 0 0 3.666zm0 11a1.833 1.833 0 1 0 0-3.667 1.833 1.833 0 0 0 0 3.666z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default ShareIcon
